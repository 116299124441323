import React from "react";
// import TgSvg from "../../../svg/tg.svg";
// import VkSvg from "../../../svg/vk.svg";
// import GitLabSvg from "../../../svg/gitlab.svg";
import { ReactComponent as TgSvg } from "../../../svg/tg.svg";
import { ReactComponent as VkSvg } from "../../../svg/vk.svg";
import { ReactComponent as GitLabSvg } from "../../../svg/gitlab.svg";

// import Image from "../Image/Image";
import styles from "./SocialLinks.module.sass";

function SocialLinks() {
  return (
    <div className={styles.linksList}>
      <a
        href="https://t.me/+79625616959"
        target="_blank"
        rel="noreferrer noopener"
        className={styles.link + " " + styles.linkTg}
      >
        <TgSvg className={styles.linkIcon}/>
      </a>
      <a
        href="https://vk.com/zirek_tat"
        target="_blank"
        rel="noreferrer noopener"
        className={styles.link + " " + styles.linkVk}
      >
        <VkSvg className={styles.linkIcon}/>
      </a>
      <a
        href="https://gitlab.com/Ilias_77"
        target="_blank"
        rel="noreferrer noopener"
        className={styles.link}
      >
        <GitLabSvg className={styles.linkIcon}/>
      </a>
    </div>
  );
}

export default SocialLinks;
