import classNames from "classnames";
import { useTheme } from "../context/theme/hooks";
import Header from "@components/UI/Header/Header";


export const Layout = ( {children, className} ) => {
const { theme } = useTheme();

return (
    <div className={ classNames( 'page '+theme?.value, className ) }>
        <Header />
        {children}
    </div>
  )
}
