import classNames from "classnames"
import styles from "./style.module.sass";

export const Field = ( { className, children, error, ...props } ) => {
  return (
    <div className={ classNames( className ) }>
        { children }
        
        { error && <span className={styles.error}>{error}</span> }
    </div>
  )
}