import styles from "./style.module.sass";
import { useCallback } from "react";
import { useTextField } from '../../../hooks/form-validation-hooks/useTextField';
import { required } from '../../../validators/required';
import { onlyLetters } from '../../../validators/only-letters';
import { phoneOrEmail } from '../../../validators/phone-or-email';
import classNames from 'classnames';
import TelegramService from "../../../API/TelegramService";
import { MyInputNew } from "../../UI/Input/MyInputNew";
import { Textarea } from "../../UI/Textarea/component";
import ButtonGreen from "../../UI/Button/ButtonGreen";
import { Field } from "../../UI/Field/component";
import { useForm } from "../../../hooks/form-validation-hooks/useForm";


export const ContactForm = function({ className, ...props }) {
  
  const name = useTextField('name', [ required(), onlyLetters() ]);
  const contact = useTextField('contact', [ required(), phoneOrEmail() ]);
  const message = useTextField('message', [ required() ]);
  
  const form = useForm({
    fields: [name, contact, message],
    apiCall: async() =>  {
      const sendLetter = `Имя: ${name.value} \nКонтакт: ${contact.value} \nСообщение: ${message.value}`;

      return await TelegramService.sendMessage(sendLetter);
    },
    onSuccess: () => {
      setDefaultFormData();
    },
    onFailure: () => {},
  });

  const setDefaultFormData = useCallback( () => {
    name.reset();
    contact.reset();
    message.reset();
  }, [name, contact, message] );
  

  return (
    <form
      {...props}
      className={ classNames( styles.form, className ) }
      onSubmit={form.handleFormSubmit}
    >
      <div className={ styles.formBody } >
        <h3>Обратная связь</h3>

        <Field error={name.error}>
          <MyInputNew
            type="text"
            placeholder="Как к вам обращаться"
            autoComplete="off"
            value={name.value}
            onChange={name.handleChange}
            onBlur={name.handleBlur}
            name="name"
            error={name.error}
          />
        </Field>
        <br/>

        <Field error={contact.error}>
          <MyInputNew
            type="text"
            placeholder="E-mail или номер телефона"
            autoComplete="off"
            value={contact.value}
            onChange={contact.handleChange}
            onBlur={contact.handleBlur}
            name="contact"
            error={contact.error}
          /> 
        </Field>
        <br />

        <Field error={message.error}>
          <Textarea
            placeholder="Введите текст сообщения"
            value={message.value}
            onChange={message.handleChange}
            onBlur={message.handleBlur}
            name="message"
            error={message.error}
          />
        </Field>
        <br />

        <ButtonGreen
          className={styles.submitBtn}
          disabled={form.isSending || form.hasFieldErrors}
        >
          ОТПРАВИТЬ
        </ButtonGreen>
      </div>
    </form>
  )
}
