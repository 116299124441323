import React from "react";
import styles from "./style.module.sass";

export const Skill = function({ name, level = 100, ...props }) {

  return (
    <div className={styles.item}>
      <div className={styles.name}>{name}</div>
      <div className={styles.fullLevel}>
        <div className={styles.level} style={{ width: level + "%" }}></div>
      </div>
    </div>
  );
}