import React, { useEffect, useRef, useState } from "react";
import styles from "./MyInput.module.sass";

function MyInput({
  value,
  setIsInputValid,
  required,
  validateName,
  onChange,
  ...props
}) {
  onChange = onChange ?? "";
  const regMail = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
  const regPhone =
    /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/;

  const [error, setError] = useState("");
  const [isValidate, setIsValidate] = useState(true);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (setIsInputValid) {
      setIsInputValid(validate());
    }
    return setIsValidate(validate());
    // eslint-disable-next-line
  }, [value]);

  function validate() {
    if (required) {
      if (!value) {
        setError("Заполните поле");
        return false;
      }
    }
    if (validateName && validateName === "contact") {
      if (!regMail.test(value) && !regPhone.test(value.replace(/\s/g, ""))) {
        setError("Неферный формат");
        return false;
      }
    }
    setError("");
    return true;
  }

  return (
    <div className={styles.wrapper}>
      <input
        {...props}
        value={value}
        className={styles.input + " " + (!isValidate && styles.inputError)}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <span className={styles.error}>{error}</span>
    </div>
  );
}
export default MyInput;
