import { useMemo, useState } from "react";
import { ThemeContext } from "./themeContext";
import { getDefaultTheme } from "./themeContext";

export const ThemeContextProvider = function( {children} ) {
    
    const [theme, setTheme] = useState( getDefaultTheme() );  
    const contextValue = useMemo( () => ({theme, setTheme}), [theme]);

    return (
        <ThemeContext.Provider value={contextValue}>
          {children}
        </ThemeContext.Provider>
    )
}
