import React from "react";
import styles from "./Buttons.module.sass";
import stylesGreen from "./ButtonGreen.module.sass";

function ButtonGreen({ className, ...props }) {
  className = className ?? "";
  return (
    <button
      {...props}
      className={styles.button + " " + stylesGreen.button + " " + className}
    ></button>
  );
}

export default ButtonGreen;
