import React from "react";
import { Link } from "react-router-dom";
import Image from "../UI/Image/Image";
import styles from "./WorkItem.module.sass";

function WorkItem({ work }) {
  return (
    <Link to={"/work/" + work.id} className={styles.work}>
      <div className={styles.pictureWrapper}>
        <Image
          src={work.preview_img_small}
          className={styles.picture}
          loading="lazy"
        />
      </div>
      <h6 className={styles.name}>{work.title}</h6>
    </Link>
  );
}

export default WorkItem;
