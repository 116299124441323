import { createContext } from "react";

export const themes = {
    'theme-light': {
        value: 'theme-light',
        label: 'день'
    },
    'theme-dark': {
        value: 'theme-dark',
        label: 'ночь'
    }
};

export const getDefaultTheme =  function() {
    const date = new Date();
    return date.getHours() > 7 && date.getHours() < 19 ? themes['theme-light'] : themes['theme-dark'];
}


export const ThemeContext = createContext( getDefaultTheme() );