import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./styles/App.sass";
import Root from "./Root";
import { Layout } from "./layout/Layout";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Root />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
