import { useCallback, useState } from 'react';
import { validate as validateValue} from '../../validators';

export const useTextField = ( id, validators, init = "") => {

  const [value, setValue] = useState(init);
  const [error, setError] = useState(null);
  
  const handleChange = useCallback(
    async (event) => {
      const value = event.target.value;

      setValue(value);
      setError(await validateValue(value, validators));
    },
    [validators]
  );
    
  const handleBlur = useCallback(async () => {
    setError(await validateValue(value, validators));
  }, [value, validators]);


  const hasError = useCallback(async () => {
    const err = await validateValue(value, validators);
    setError(err);

    return !!err;
  }, [value, validators]);


  const reset = () => {
    setValue(init);
  };

  return {
    id,
    value,
    error,
    reset,
    hasError,
    handleChange,
    handleBlur,
  };
}
