export default class TelegramService {
    static chatid = "271401043";
    static token = "5314277166:AAGrg0P8Qrs2TgMB2pkpuENvMyoXEFF8cTE";

    static async sendMessage(text) {
        const data = {
            parse_mode: "HTML",
            text,
        };
      
        let response = await fetch(
            "https://api.telegram.org/bot" + this.token + "/sendMessage?chat_id=" + this.chatid,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json;charset=utf-8",
              },
              body: JSON.stringify(data),
            }
          );
          if (response.ok) {
            let result = await response.json();
            return result;
          } else {
              return response.status;
          }
      }
}