// import axios from "axios";

export default class WorkService {
  static works = {
    data: [
      {
        id: 1,
        sort: 25,
        title: "Интерактивное агенство Daring&Young",
        link: {
          name: "dy-design.com",
          value: "https://dy-design.com/",
        },
        preview_img: require("../images/works/dy-design/preview.jpg"),
        preview_img_small: require("../images/works/dy-design/preview_sm.jpg"),
        technologies: ["Gulp", "Stylus", "JS", "Jquery"],
        detailText: `Выполнен сайт интерактивного агенста. Сайт сделан в виде single page application, но без использование фреймворков. Реализован кастомный скролл страниц с drag-and-drop. Сделано бесконечное прокручивание на странице выполненных работ.`,
        interestingThings: ``,
        maketImages: [],
      },
      {
        id: 2,
        sort: 100,
        title: `Центр стрелковой подготовки "Булат"`,
        link: {
          name: "cspbulat.ru",
          value: "https://cspbulat.ru/",
        },
        preview_img: require("../images/works/cspbulat/preview.jpg"),
        preview_img_small: require("../images/works/cspbulat/preview_sm.jpg"),
        technologies: ["Gulp", "Stylus", "JS", "Jquery"],
        detailText: ``,
        interestingThings: ``,
        maketImages: [],
      },
      {
        id: 3,
        title: "Интернет-магазин инструментов",
        sort: 20,
        link: {
          name: "mactak.ru",
          value: "https://mactak.ru/",
        },
        preview_img: require("../images/works/mactak/preview.jpg"),
        preview_img_small: require("../images/works/mactak/preview_sm.jpg"),
        technologies: ["Gulp", "Stylus", "JS", "Jquery"],
        detailText: ``,
        interestingThings: ``,
        maketImages: [],
      },
      {
        id: 4,
        title: "Сайт дизайнера интерьеров",
        sort: 100,
        link: {
          name: "mmorozov.com",
          value: "http://mmorozov.com/",
        },
        preview_img: require("../images/works/mmorozov/preview.jpg"),
        preview_img_small: require("../images/works/mmorozov/preview_sm.jpg"),
        technologies: ["Gulp", "Stylus", "JS", "Jquery"],
        detailText: ``,
        interestingThings: ``,
        maketImages: [],
      },
      {
        id: 5,
        title: "Сайт федерации практической стрельбы Республики Татарстан",
        sort: 30,
        link: {
          name: "fps-rt.ru",
          value: "https://fps-rt.ru/",
        },
        preview_img: require("../images/works/fps-rt/preview.jpg"),
        preview_img_small: require("../images/works/fps-rt/preview_sm.jpg"),
        technologies: ["Gulp", "Stylus", "JS", "Jquery"],
        detailText: ``,
        interestingThings: ``,
        maketImages: [],
      },
      {
        id: 6,
        title: "Магазин мужской одежды",
        sort: 40,
        link: {
          name: "empiresuit.com",
          value: "https://empiresuit.com/",
        },
        preview_img: require("../images/works/empiresuit/preview.jpg"),
        preview_img_small: require("../images/works/empiresuit/preview_sm.jpg"),
        technologies: ["Gulp", "Stylus", "JS", "Jquery", "CMS Wordpress"],
        detailText: `Создан интернет-магазин мужской одежды. Интегрирован с CMS Wordpress. Заказы с сайта поступают на телеграм аккаунт.`,
        interestingThings: [
          `Размерная сетка на странице товара, например, у <a href="https://empiresuit.com/product/browncoat/" target="_blank">пальто</a>`,
        ],
        maketImages: [
          {
            src: require("../images/works/empiresuit/main.jpg"),
          },
          {
            src: require("../images/works/empiresuit/catalog.jpg"),
          },
          {
            src: require("../images/works/empiresuit/product.jpg"),
          },
          {
            src: require("../images/works/empiresuit/basket.jpg"),
          },
          {
            src: require("../images/works/empiresuit/catalog.jpg"),
          },
        ],
      },
      {
        id: 7,
        title: "Сайт умных пен",
        sort: 35,
        link: {
          name: "iqfoam.ru",
          value: "https://iqfoam.ru/",
        },
        preview_img: require("../images/works/iqfoam/preview.jpg"),
        preview_img_small: require("../images/works/iqfoam/preview_sm.jpg"),
        technologies: [
          "Gulp",
          "Stylus",
          "JS",
          "Jquery",
          "Vue.js",
          "CMS Bitrix",
        ],
        detailText: ``,
        interestingThings: [
          `Ховер на продкуты на странице <a href="https://iqfoam.ru/products/" target="_blank">"Продукция"</a>`,
          `Анимации на <a href="https://iqfoam.ru/products/neococos/" target="_blank">детальной странице</a> товаров`,
          `Горизонтальный скролл в разделе "История компании" на странице <a href="https://iqfoam.ru/about/" target="_blank">"О компании"</a>`,
          `Карта на странице <a href="https://iqfoam.ru/contacts/" target="_blank">"Контакты"</a>`,
        ],
        maketImages: [
          {
            src: require("../images/works/iqfoam/main.jpg"),
          },
          {
            src: require("../images/works/iqfoam/catalog.jpg"),
          },
          {
            src: require("../images/works/iqfoam/product-cooper.jpg"),
          },
          {
            src: require("../images/works/iqfoam/product-energex.jpg"),
          },
          {
            src: require("../images/works/iqfoam/product-flex.jpg"),
          },
          {
            src: require("../images/works/iqfoam/product-neococos.jpg"),
          },
          {
            src: require("../images/works/iqfoam/product-neolatex.jpg"),
          },
          {
            src: require("../images/works/iqfoam/product-titanium.jpg"),
          },
          {
            src: require("../images/works/iqfoam/about.jpg"),
          },
          {
            src: require("../images/works/iqfoam/partners.jpg"),
          },
          {
            src: require("../images/works/iqfoam/contacts.jpg"),
          },
          {
            src: require("../images/works/iqfoam/buy.jpg"),
          },
        ],
      },
      {
        id: 8,
        sort: 15,
        title: "Сайт дизайн-системы компании БАРС Груп",
        link: {
          name: "design.bars.group",
          value: "https://design.bars.group/",
        },
        preview_img: require("../images/works/design-bars-group/preview.jpg"),
        preview_img_small: require("../images/works/design-bars-group/preview_sm.jpg"),
        technologies: ["Gulp", "Sass", "Js", "Jquery", "CMS Wordpress"],
        detailText: `Сверстан сайт дизайн-системы компании БАРС Груп, интегрирован с CMS Wordpress. Главная страница насыщена анимациями: проигрывание видео на главном экране; смена картинок в зависимости от отображаемого раздела страницы; кастомизированный курсор на слайдере. В административной части сайта реализованы модули для заполнения внутренних страниц.`,
        interestingThings: [
          `Кастомизированный курсор на слайдере главной страницы.`,
          `Копирование цвета на кликом на цвет на странице <a href="https://design.bars.group/foundations/color/" target="_blank">"Основы / Цвет"</a>`,
        ],
        maketImages: [
          {
            src: require("../images/works/design-bars-group/main.jpg"),
          },
          {
            src: require("../images/works/design-bars-group/internal-color.jpg"),
          },
          {
            src: require("../images/works/design-bars-group/internal-getstarted.jpg"),
          },
          {
            src: require("../images/works/design-bars-group/internal-iconography.jpg"),
          },
          {
            src: require("../images/works/design-bars-group/internal-tokens.jpg"),
          },
          {
            src: require("../images/works/design-bars-group/internal-typography.jpg"),
          },
        ],
      },
      {
        id: 9,
        sort: 22,
        title: "WEB-сервис для клиники",
        preview_img: require("../images/works/zdorovie-surgery/preview.png"),
        preview_img_small: require("../images/works/zdorovie-surgery/preview_sm.jpg"),
        technologies: [
          "Gulp",
          "Sass",
          "Js",
          "Vue.js",
          "Jquery",
          "PHP",
          "SOAP",
          "MySQL",
        ],
        detailText: `Разработан веб-сервис для внутренного пользования медицинской клиникой. <br>Система позволяет записать пациента на операцию: выбрать врача, тип операции, дату, операционную и дополнительные услуги. Предусмотрено вде роли: врач и администратор.`,
        // interestingThings: [
        //   `Кастомизированный курсор на слайдере главной страницы.`,
        //   `Копирование цвета на кликом на цвет на странице <a href="https://design.bars.group/foundations/color/" target="_blank">"Основы / Цвет"</a>`,
        // ],
        maketImages: [
          {
            src: require("../images/works/zdorovie-surgery/admin-1.png"),
          },
          {
            src: require("../images/works/zdorovie-surgery/admin-2.png"),
          },
          {
            src: require("../images/works/zdorovie-surgery/doctor.jpg"),
          },
          {
            src: require("../images/works/zdorovie-surgery/doctor-operation-detail.jpg"),
          },
          {
            src: require("../images/works/zdorovie-surgery/doctor-record-1.jpg"),
          },
          {
            src: require("../images/works/zdorovie-surgery/doctor-record-1-calendar.jpg"),
          },
          {
            src: require("../images/works/zdorovie-surgery/doctor-record-2.png"),
          },
          {
            src: require("../images/works/zdorovie-surgery/doctor-record-2-ready.png"),
          },
          {
            src: require("../images/works/zdorovie-surgery/doctor-record-3.png"),
          },
        ],
        videoExamples: [
          {
            src: require("../videos/works/zdorovie-surgery/example.mp4"),
          },
        ],
      },
      {
        id: 10,
        sort: 10,
        title: "Сервис телемедицины",
        preview_img: require("../images/works/zdorovie-telemed/preview.jpg"),
        preview_img_small: require("../images/works/zdorovie-telemed/preview_sm.jpg"),
        technologies: [
          "Gulp",
          "SASS",
          "Js",
          "Vue.js",
          "Jquery",
          "PHP",
          "Yii",
          "SOAP",
          "MySQL",
        ],
        detailText: `Разработан сервис для проведение онлайн консультаций врачами. <br>`,
        interestingThings: [
          `Интеграция сервиса sms-рассылок`,
          `Интеграция эквайринга сбера`,
          `Сервер для потокового видео`,
        ],
        videoExamples: [
          {
            src: require("../videos/works/zdorovie-telemed/example-patient.mp4"),
            description: "Экран пациента",
          },
          {
            src: require("../videos/works/zdorovie-telemed/example-doctor.mp4"),
            description: "Экран врача",
          },
          {
            src: require("../videos/works/zdorovie-telemed/example-analyst.mp4"),
            description: "Экран аналитика",
          },
        ],
      },
    ],
  };

  static async getAll(limit = 10, page = 1) {
    //     const response = await axios.get(
    //       "https://jsonplaceholder.typicode.com/posts",
    //       {
    //         params: {
    //           _limit: limit,
    //           _page: page,
    //         },
    //       }
    //     );
    return this.works;
  }

  static async getById(id) {
    return {
      // eslint-disable-next-line
      data: this.works.data.filter((work) => work.id == id)[0],
    };
  }

  static async getByNamePart(chooseString) {
    return {
      data: this.works.data.filter((work) =>
        work.title.toUpperCase().indexOf(chooseString)
      )[0],
    };
  }
}
