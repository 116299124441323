import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";

import { workReducer } from "./workReducer";

const rootReducer = combineReducers({
    works: workReducer
})

export const store = createStore( rootReducer, composeWithDevTools( applyMiddleware(thunk) ) );