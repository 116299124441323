import styles from "./MyInput.module.sass";

export const MyInputNew = function({
  value,
  onChange,
  error,
  ...props
}) {
  
  return (
      <input
        {...props}
        value={value}
        className={styles.input + " " + (error && styles.inputError)}
        onChange={onChange}
      />
  );
}