import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../Container/Container";
import ProfilImg from "../../../images/profile-r-sm.jpg";
import Image from "../Image/Image";
import SocialLinks from "../SocialLinks/SocialLinks";
import styles from "./Header.module.sass";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import '../../../styles/ui/react-dropdown.sass'

import { themes } from "../../../context/theme/themeContext";
import { useTheme } from "../../../context/theme/hooks";

function Header() {
  const header = useRef(null);

  const {theme, setTheme} = useTheme();
  const [themeSelectOptions, setThemeSelectOptions] = useState([]);

  useEffect(() => {
    document.querySelector(".page").style.paddingTop =
      header.current.offsetHeight + 45 + "px";
      setThemeSelectOptions( Object.entries( themes ).map((item) => 
        item[1]
      ));
  }, []);

  return (
    <header className={styles.header} ref={header}>
      <Container>
        <div className={styles.headerBody}>
          <Link to="/" className={styles.headerLogo}>
            <Image src={ProfilImg} />
            Ilias Nafikov
          </Link>
          <div className={'flex-center'}>
            <SocialLinks />            
            
            <Dropdown options={themeSelectOptions} onChange={(v) => setTheme(v)} value={themes[theme.value]} placeholder="Выбери тему" />
          </div>
        </div>
      </Container>
    </header>
  );
}

export default Header;
