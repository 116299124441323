import { Navigate, Route, Routes } from "react-router-dom";
import { useHashAnimate } from "@hooks/useHashAnimate";
import { routes } from "./router";

function Root() {

  useHashAnimate();
  

  return (
        <Routes>
          {routes.map((route) => (
            <Route
              element={route.component}
              path={route.path}
              exact={route.exact}
              key={route.path}
            />
          ))}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
  );
}
export default Root;
