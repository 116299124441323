const defaultState = {
  works: ['as']
}

const LOAD_WORKS = 'LOAD_WORKS';
const ADD_MANY_WORKS = 'ADD_MANY_WORKS';

export const workReducer = (state = defaultState, action) => {
  switch(action.type) {
    case LOAD_WORKS:
      return {...state, works: [...state.works, action.payload] }
    case ADD_MANY_WORKS:
      return {...state, works: [...state.works, ...action.payload] }
    default:
      return state;
  }
}

export const loadWorksAction = (payload) => ({
  type: LOAD_WORKS, payload
})

export const addManyWorksAction = (payload) => ({
  type: ADD_MANY_WORKS, 
  payload
})