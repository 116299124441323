import React from "react";
import styles from "./MyUl.module.sass";

function MyUl({ lisTtems, ...props }) {
  return (
    <ul className={styles.ul}>
      {lisTtems.map((item, index) => (
        <li
          key={item}
          dangerouslySetInnerHTML={{
            __html: item,
          }}
        ></li>
      ))}
    </ul>
  );
}

export default MyUl;
