import React from "react";
import WorkItem from "./WorkItem";
import styles from "./WorkList.module.sass";

function WorksList({ works, title }) {
  if (!works.length)
    return <h2 style={{ textAlign: "center" }}>Работ пока нет (</h2>;

  return (
    <section>
      {title && <h2 style={{ textAlign: "center" }}>{title}</h2>}
      <div className={styles.worksList}>
        {works.map((work, index) => (
          <WorkItem work={work} key={"work_" + work.id} />
        ))}
      </div>
    </section>
  );
}

export default WorksList;
