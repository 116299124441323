import React, { useState, useEffect } from "react";
import ProfileImg from "../../images/profile.jpg";
import WorkService from "../../API/WorkService";
import PostFilter from "@components/PostFilter";
import Container from "@components/UI/Container/Container";
import WorksList from "@components/Works/WorkList";
import { useFetching } from "@hooks/useFetching";
import { usePosts } from "@hooks/usePosts";
import Image from "@components/UI/Image/Image";
import styles from "./style.module.sass";
// import { ContactForm } from "@components/UI/Form/ContactForm";

import iconBriefcase from "../../images/icons/briefcase.png";
import iconGraduation from "../../images/icons/graduation.png";
import iconAbout from "../../images/icons/about.png";
import { ReactComponent as IconDy } from "../../svg/dy-logo.svg";
import iconTodo from "../../images/icons/todo.png";
import { Link } from "react-router-dom";
import { Skill } from "@components/skill/component";
import { Section } from "@components/section/component";
import { useTheme } from "../../context/theme/hooks";
import classNames from "classnames";
import { ContactForm } from "../../components/forms/contactForm/component";

const COUNT_WORKS_ON_PAGE = 10;

function Home() {
  // eslint-disable-next-line
  // const [limit, setLimit] = useState(10);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [works, setWorks] = useState([]);

  const [filter, setFilter] = useState({ query: "" });
  const sortedSearchedWorks = usePosts(works, filter.query);

  // eslint-disable-next-line
  const [fetchWorks, isWorksLoading, workError] = useFetching(async () => {
    const response = await WorkService.getAll(COUNT_WORKS_ON_PAGE, page);
    // const response = await WorkService.getAll(limit, page);
    setWorks([...works, ...response.data]);
  });

  // const themeContext = useContext(ThemeContext);
  const {theme} = useTheme();

  useEffect(() => {
    fetchWorks();
    // eslint-disable-next-line
    // fetch('http://127.0.0.1:8000/api/users/');
  }, []);

  return (
    <Container isSmall>
      <div className={styles[theme.value]}>
      <main className={styles.main}>
        <div>
          <h2>Ильяс Нафиков</h2>
          <p className={styles.profileNote}>web разработчик</p>

          <nav>
            <Link
              to={{
                pathname: "/",
                hash: "#about",
              }}
              className={styles.anchor}
            >
              Обо мне
            </Link>
            <Link
              to={{
                pathname: "/",
                hash: "#skills",
              }}
              className={styles.anchor}
            >
              Навыки
            </Link>
            <Link
              to={{
                pathname: "/",
                hash: "#experience",
              }}
              className={styles.anchor}
            >
              Опыт работы
            </Link>
            <Link
              to={{
                pathname: "/",
                hash: "#portfolio",
              }}
              className={styles.anchor}
            >
              Примеры работ
            </Link>
            <Link
              to={{
                pathname: "/",
                hash: "#contact",
              }}
              className={styles.anchor}
            >
              Контакты
            </Link>
          </nav>
        </div>

        <Image src={ProfileImg} className={styles.profilePhoto} />
      </main>

      <Section id="about" title="Обо мне" headerIcon={iconAbout}>
        <div className={styles.sectionHeader}>
          <Image
            src={iconGraduation}
            className={ classNames( styles.sectionHeaderIconSmall, styles.darkThemeInvert) }
          />
          <h6>Образование</h6>
        </div>
        <p>
          <strong>Магистратура:</strong> Казанский (Приволжский) федеральный
          университет / Инстутут вычислительной математики и информационных
          технологий / <strong>Специальность</strong>: Информационные системы
          и технологии / <strong>2018</strong>
        </p>
        <p>
          <strong>Бакалавриат:</strong> Казанский (Приволжский) федеральный
          университет / Инстутут вычислительной математики и информационных
          технологий / <strong>Специальность</strong>: Информационные системы
          и технологии / <strong>2016</strong>
        </p>
      </Section>
      
      <Section id="skills" title="Навыки" headerIcon={iconTodo}>
        <Skill name="JS" level="65" />
        <Skill name="Vue.js" level="60" />
        <Skill name="React.js" level="55" />
        <Skill name="Sass, Stylus" level="85" />
        <Skill name="Git" level="55" />
        <Skill name="Laravel" level="55" />
        <Skill name="CMS Bitrix" level="80" />
        <Skill name="CMS WordPress" level="80" />
        <Skill name="PHP" level="50" />
        <Skill name="Yii" level="50" />
      </Section>

      <Section id="experience" title="Опыт работы" headerIcon={iconTodo}>
        <div className={styles.experienceItem}>
          <IconDy className={styles.experienceIcon} />
          <div>
            <p>
              Web разработчик в ООО "Дэйринг энд янг" <br />
              июль 2016 - настоящее время
            </p>
          </div>
        </div>
      </Section>

      <Section id="portfolio" title="Выполненные работы" headerIcon={iconBriefcase}>
        <PostFilter filter={filter} setFilter={setFilter} />
        <WorksList works={sortedSearchedWorks} />
      </Section>

      <section id="contact">

        {/* <ContactForm className={styles.contactForm}></ContactForm> */}
        <ContactForm className={styles.contactForm}/>
      </section>

      </div>
    </Container>
  );
}

export default Home;
