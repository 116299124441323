import { useMemo } from "react";

export const usePosts = (posts, query) => {
  const tmpPosts = posts.slice(0);
  const sortedSearchedPosts = useMemo(() => {
    return tmpPosts
      .sort((a, b) => (a.sort >= b.sort ? 1 : -1))
      .filter((post) => post.title.toLowerCase().includes(query.toLowerCase()));
    // eslint-disable-next-line
  }, [query, posts]);
  return sortedSearchedPosts;
};
