import React, { useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./Video.module.sass";
import { useRef } from "react";
import { useState } from "react";
import { useDevice } from "../../../hooks/useDevice";

function Video({ src, alt, className, ...attrs }) {
  const video = useRef(null);
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);

  const playVideo = function () {
    if (isVideoPlayed) {
      video.current.pause();
      setIsVideoPlayed(false);
    } else {
      video.current.play();
      setIsVideoPlayed(true);
    }
  };
  useEffect(() => {
    video.current.addEventListener("ended", (event) => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    video.current.controls = isVideoPlayed;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoPlayed]);

  return (
    <div className={styles.videoBlock + " " + (useDevice() && styles.isMobile)}>
      <div
        onEnded={() => setIsVideoPlayed(false)}
        onClick={playVideo}
        className={styles.videoWrapper}
      >
        <video
          className={styles.video}
          src={src}
          ref={video}
          preload="true"
        ></video>
        <div
          className={
            styles.videoControllers +
            " " +
            (isVideoPlayed ? styles.videoControllersDisabled : "")
          }
        >
          <div
            className={
              styles.videoControllerItem +
              " " +
              (isVideoPlayed
                ? styles.videoControllerPause
                : styles.videoControllerPlay)
            }
          >
            {/* {isVideoPlayed ? "pause" : "play"} */}
          </div>
        </div>
      </div>
      {attrs.description ? (
        <h6 className={styles.videoDescription}>{attrs.description}</h6>
      ) : (
        ""
      )}
    </div>
  );
}
Video.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};
Video.defaultProps = {
  src: "",
  alt: "Название видео",
  className: "",
};

export default Video;
