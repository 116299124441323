import React from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";

function Image({ src, alt, className, ...attrs }) {
  //   const classes = classNames(className);
  return <img src={src} alt={alt} className={className} {...attrs} />;
  //   return <img src={src} alt={alt} className={classes} {...attrs} />;
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};
Image.defaultProps = {
  src: "https://via.placeholder.com/100x100",
  alt: "Название картинки",
  className: "",
};

export default Image;
