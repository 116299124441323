import Home from "../pages/home-page/component";
import WorkDetail from "../pages/work-page/component";

export const routes = [
    {
      path: "/",
      component: <Home />,
      exact: true,
    },
    {
      path: "/work/:id",
      component: <WorkDetail />,
      exact: true,
    }
];