import React from "react";
import styles from "./Container.module.sass";

function Container({isSmall, ...props}) {
  let className = styles.container;
  if (isSmall)
    className += ' '+styles.containerSm;

  return <div className={className}>{props.children}</div>;
}

export default Container;
