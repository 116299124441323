import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useFetching } from "../../hooks/useFetching";
import WorkService from "../../API/WorkService";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

import Container from "../../components/UI/Container/Container";
import Image from "../../components/UI/Image/Image";
import styles from "./style.module.sass";
import MyUl from "../../components/UI/Ul/MyUl";
import Video from "../../components/UI/Video/Video";
import LinkBack from "../../components/UI/LinkBack/LinkBack";

function WorkDetail() {
  const params = useParams();
  const [work, setWork] = useState({});

  const [isSliderPhotoOpen, setIsSliderPhotoOpen] = useState(false);
  const [zoomPhotoIndex, setZoomPhotoIndex] = useState();

  const [fetchWorkById, isLoading, error] = useFetching(async (id) => {
    const response = await WorkService.getById(params.id);
    setWork(response.data);
  });

  let zoomTimeout = null;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchWorkById(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!work) {
    return <Navigate replace to="/" />;
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    afterChange: (index) => {
      if (zoomTimeout) clearTimeout(zoomTimeout);
    },
    // beforeChange: (current, next) => {},
  };

  return (
    !isLoading &&
    !error && (
      <Container>
        <section className={"m-top-0"}>
          <h1>{work.title}</h1>
          <LinkBack text="Назад к работам"/>
          {work.link && (
            <a
              href={work.link.value}
              target="_blank"
              rel="noreferrer"
              className={styles.workSiteLink}
            >
              <Image
                src={work.preview_img_small}
                className={styles.workDetailImg}
              />
              <span className="span">Перейти на сайт {work.link.name}</span>
            </a>
          )}
        </section>


        {work.detailText && (
          <section>
            <h3>Подробнее о проекте</h3>
            <div
              className={"width-70"}
              dangerouslySetInnerHTML={{
                __html: work.detailText,
              }}
            ></div>
          </section>
        )}

        {work.interestingThings && (
          <section>
            <h3>Интересные вещи</h3>
            <MyUl lisTtems={work.interestingThings}></MyUl>
          </section>
        )}

        {work.technologies && work.technologies.length ? (
          <section>
            <h3>Используемые технологии</h3>
            <div>{work.technologies.join(" / ")}</div>
          </section>
        ) : (
          ""
        )}

        {work.videoExamples && work.videoExamples.length && (
          <section>
            <h3>Видео пример{work.videoExamples.length > 1 ? "ы" : ""}</h3>
            {work.videoExamples.map((video, index) => (
              <Video
                key={"video-" + index}
                src={video.src}
                description={video.description}
              />
            ))}
          </section>
        )}

        {work.maketImages && work.maketImages.length ? (
          <section>
            <h3>Некоторые экраны макета</h3>
            <Slider
              {...sliderSettings}
              className={"my-slick-slider " + styles.workSlider}
            >
              {work.maketImages.map((image, index) => (
                <div
                  key={image}
                  className={styles.workSlide}
                  onClick={() => {
                    zoomTimeout = setTimeout(() => {
                      setIsSliderPhotoOpen(true);
                      setZoomPhotoIndex(index);
                    }, 800);
                  }}
                >
                  <Image src={image.src} />
                </div>
              ))}
            </Slider>
          </section>
        ) : (
          ""
        )}

        {isSliderPhotoOpen && zoomPhotoIndex !== undefined ? (
          <Lightbox
            open={isSliderPhotoOpen}
            index={zoomPhotoIndex}
            nextSrc={
              work.maketImages[(zoomPhotoIndex + 1) % work.maketImages.length]
            }
            prevSrc={
              work.maketImages[
                (zoomPhotoIndex + work.maketImages.length - 1) %
                  work.maketImages.length
              ]
            }
            close={() => {
              setIsSliderPhotoOpen(false);
            }}
            slides={work.maketImages}
            plugins={[Fullscreen]}
          />
        ) : (
          ""
        )}
      </Container>
    )
  );
}

export default WorkDetail;
