import styles from "./style.module.sass";
import classNames from "classnames";

export const Textarea = ({ value, error, ...props }) => {
  return (
    <textarea value={value} {...props} className={ classNames( styles.textarea, (error && styles.textareaError) ) } ></textarea>
  );
}


