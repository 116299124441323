import classNames from "classnames";
import { useTheme } from "../../context/theme/hooks";
import styles from "./style.module.sass";
import Image from "@components/UI/Image/Image";

export const Section =  function( { title, headerIcon, children, ...props} ) {

  const { theme } = useTheme();

  return <section {...props} className={styles.section}>
    <div className={styles.header}>
      <Image src={headerIcon} className={ classNames(styles.icon, { [styles.icon_theme_dark] : theme.value === "theme-dark" } ) } />
      <h3>{ title }</h3>
    </div>
    
    <div>
      { children }
    </div>
  </section>
}
