import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useHashAnimate = () => {
    const history = useLocation();
    useEffect(() => {
      if (history.hash) {
        const headerHeight = document.querySelector("header").offsetHeight;
        const scrollTo = document.getElementById(history.hash.slice(1));
        if (!scrollTo) return;
  
        const y =
          scrollTo.getBoundingClientRect().top +
          window.scrollY -
          headerHeight -
          50;
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
      // eslint-disable-next-line
    }, [history]);
}