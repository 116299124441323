import React from "react";
import { ReactComponent as ArrowBackSvg } from "../../../svg/arrow-back.svg";

import styles from "./LinkBack.module.sass";
import { Link } from "react-router-dom";

function LinkBack({text}) {

  window.addEventListener('scroll', function() {
    console.log(window.scrollY);
  })

  return (
    <Link to={"/#portfolio"} className={styles.link}>
      <ArrowBackSvg className={styles.icon}/>
        {text ?? 'Назад'}
    </Link>
  );
}

export default LinkBack;
